import { makeAutoObservable } from "mobx";
import { Availability, isAppleChannelId, } from "../..";
export const PAY_PO_CHANNEL_ID = 227;
const P24_CHANNEL_TAG = "P24Channel";
const PAYMENT_CHANNELS_WITH_ADDRESS_REQUIRED = [PAY_PO_CHANNEL_ID];
export class Payment {
    constructor(params) {
        this.isIosApp = params.isIosApp;
        this.fulfillmentInstructions = params.fulfillmentInstructions;
        this.address = params.address;
        this.medium = params.medium;
        this.isRestored = params.isRestored;
        makeAutoObservable(this);
    }
    static fromStorageData(params) {
        const { paymentMethods, paymentChannels } = params.restaurant;
        const storedMedium = params.storageData;
        let isStoredMediumAvailable = false;
        if (storedMedium !== null && storedMedium.type === "method") {
            const availableMethod = paymentMethods === null || paymentMethods === void 0 ? void 0 : paymentMethods.findWhere((method) => method === storedMedium.method);
            if (availableMethod !== undefined) {
                isStoredMediumAvailable = true;
            }
        }
        if (storedMedium !== null && storedMedium.type === "channel") {
            const availableChannel = paymentChannels === null || paymentChannels === void 0 ? void 0 : paymentChannels.filtered((channel) => channel.enabled === true).findWhere((channel) => equalPaymentChannelIds(channel.id, storedMedium.channelId));
            if (availableChannel !== undefined) {
                isStoredMediumAvailable = true;
            }
        }
        return new Payment({
            isIosApp: params.isIosApp,
            fulfillmentInstructions: params.fulfillmentInstructions,
            address: params.paymentAddress,
            medium: isStoredMediumAvailable ? storedMedium : null,
            isRestored: isStoredMediumAvailable,
        });
    }
    get method() {
        // TODO: default payment method?
        if (!this.medium) {
            return "Cash";
        }
        return this.medium.method;
    }
    // TODO: different abstraction for channels with specific logic, like PayPo & Blik
    get isAddressRequired() {
        if (this.medium === null || this.medium.type !== "channel") {
            return false;
        }
        return (this.medium.channelId.tag === P24_CHANNEL_TAG &&
            PAYMENT_CHANNELS_WITH_ADDRESS_REQUIRED.includes(this.medium.channelId.contents));
    }
    get availability() {
        const { fulfillmentMethodType } = this.fulfillmentInstructions;
        return Availability.composite([
            Availability.boolean({
                PaymentMediumMissing: this.medium === null,
                OnlinePaymentMethodRequired: fulfillmentMethodType !== null &&
                    fulfillmentMethodType === "NoContactDelivery" &&
                    this.medium !== null &&
                    this.medium.type === "method",
            }),
            this.isAddressRequired
                ? this.address.availability
                : Availability.available(),
        ]);
    }
    /**
     * Checkout data only for online payment (channel id etc.)
     */
    checkoutData(applePayToken) {
        if (this.medium === null || this.medium.type !== "channel") {
            return null;
        }
        return {
            applePayToken: applePayToken === null ? null : applePayToken.checkoutData,
            channel: this.medium.channelId,
            address: this.isAddressRequired ? this.address.checkoutData : null,
            blikCode: null,
            cardToken: null,
            isIosApp: this.isIosApp,
        };
    }
    get isApplePay() {
        var _a;
        return (((_a = this.medium) === null || _a === void 0 ? void 0 : _a.type) === "channel" && isAppleChannelId(this.medium.channelId));
    }
    get storageData() {
        return this.medium;
    }
}
export function equalPaymentChannelIds(a, b) {
    if (a.tag !== "StripeChannel" && b.tag !== "StripeChannel") {
        return a.tag === b.tag && a.contents === b.contents;
    }
    return a.tag === "StripeChannel" && b.tag === "StripeChannel";
}
